import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { Modal } from "../../components/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { PolicySellFilters } from "../../models/PolicySell";
import { clearPolicySells, fetchPolicySells } from "../../state/policySells/actions";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getCarriers } from "../../enums/Carrier";
import { useEffect, useMemo, useState } from "react";
import { User } from "../../models/User";
import Select from "react-select";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";

interface Props {
    isFilterModalOpen: boolean;
    setFilterModalOpen: (x: boolean) => void;
}

export const PolicySellsFilterModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const users = useSelector((state: any) => state.users.users);
    const policySellsState = useSelector((state: any) => state.policySells);
    const [currentAgents, setCurrentAgents] = useState<any[]>();
    const [defualtStartDate, setDefaultStartDate] = useState<any>();
    const [defualtEndDate, setDefaultEndDate] = useState<any>();

    const selectedAgents = useMemo(() => {
        return policySellsState.filters?.agents?.map((x: number) => {
            const user = users.find((y: User) => y.id === x);
            return {
                label: `${user.first_name} ${user.last_name}`,
                value: user.id
            }
        })
    }, [policySellsState.filters?.agents, users]);

    useEffect(() => {
        setCurrentAgents(selectedAgents)

        const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
        const startDate = moment(endDate).subtract(1, 'years').hours(0).minutes(0).seconds(0).toDate();

        const end = moment(endDate).format('YYYY-MM-DD');
        const start = moment(startDate).format('YYYY-MM-DD');
        setDefaultStartDate(start);
        setDefaultEndDate(end);

    }, [selectedAgents, defualtStartDate, setDefaultStartDate, defualtEndDate, setDefaultEndDate]);

    const agents = useMemo(() => {
        return users.map((x: any) => {
            return {
                label: `${x.first_name} ${x.last_name}`,
                value: x.id
            }
        })
    }, [users]);

    const methods = useForm<any>({
        defaultValues: {
            client_first_name: policySellsState.filters?.client_first_name,
            client_last_name: policySellsState.filters?.client_last_name,
            client_phone: policySellsState.filters?.client_phone,
            carrier: policySellsState.filters?.carrier,
            agents: policySellsState.filters?.agents,
            free_plan: policySellsState.filters?.free_plan
        }
    });

    const { handleSubmit, control, reset, setValue } = methods;

    const getLocalTimeZone = () => {
        const timeZoneOffset = new Date().getTimezoneOffset();

        if(timeZoneOffset > 0) {
            const hours = Math.floor(timeZoneOffset / 60);
            const minutes = timeZoneOffset % 60;
            const sHours = `-${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        } else {
            const hours = Math.floor(timeZoneOffset / 60) * -1;
            const minutes = timeZoneOffset % 60 * -1;
            const sHours = `+${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        }
    }

    useEffect(() => {
        setValue("client_first_name", policySellsState.filters?.client_first_name);
        setValue("client_last_name", policySellsState.filters?.client_last_name);
        setValue("client_phone", policySellsState.filters?.client_phone);
        setValue("carrier", policySellsState.filters?.carrier);
        setValue("free_plan", policySellsState.filters?.free_plan);
        setValue("start_date", policySellsState.dates?.startDate);
        setValue("end_date", policySellsState.dates?.endDate);
    },[policySellsState]);

    const onSubmit: SubmitHandler<any> = async (data: any) => {

        if(moment(data.start_date).isAfter(data.end_date)) {
            toast(t("START_DATE_BEFORE_END_DATE_MESSAGE"));
        } else {
            if (currentAgents?.length && currentAgents?.length > 0) {
                data.agents = currentAgents?.map((x: any) => x.value);
            }

            const timeZone = getLocalTimeZone();
            
            dispatch(clearPolicySells())
            dispatch(fetchPolicySells({
                startDate: data.start_date,
                endDate: data.end_date,
                timeZone: timeZone,
                page: 0,
                pageSize: policySellsState.paginatedOptions.pageSize,
                filters: data 
            }));
            props.setFilterModalOpen(false);
        }
    };

    return(
        <div>
            <ToastContainer progressStyle={{"background": "#D4AF37"}} />
            <Modal isOpen={props.isFilterModalOpen} onClose={() => props.setFilterModalOpen(false)} label={t("FILTERS")} onSave={handleSubmit(onSubmit)} thirdButton={{
                label: t("CLEAR_FILTERS"),
                icon: <TrashIcon />,
                onClick: () =>  {
                    reset({ 
                        client_first_name: "",
                        client_last_name: "",
                        client_phone: "",
                        carrier: undefined,
                        agents: undefined,
                        free_plan: "",
                        start_date: defualtStartDate,
                        end_date: defualtEndDate
                    });
                    setCurrentAgents([]);
                }
            }}>
                <Form handleOnSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        <div className={"w-full px-3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("AGENTS")}
                            </label>
                            <Select
                                isMulti
                                isSearchable
                                isClearable
                                isLoading={agents.length === 0}
                                onChange={(inputValue: any) => setCurrentAgents(inputValue)}
                                name="agent"
                                options={agents}
                                value={currentAgents}
                                placeholder="Select agents"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                maxMenuHeight={200}
                                classNames={{
                                    control: (state) =>
                                        'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
                                }}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: 'grey-50',
                                    }),
                                }}
                            />
                        </div>
                        <ReactiveFormInput
                            control={control}
                            value={""}
                            label={t("CLIENT_FIRST_NAME")}
                            name="client_first_name" />
                        <ReactiveFormInput
                            control={control}
                            value={""}
                            label={t("CLIENT_LAST_NAME")}
                            name="client_last_name" />
                        <ReactiveFormInput
                            control={control}
                            value={""}
                            label={t("CLIENT_PHONE")}
                            name="client_phone" />
                        <ReactiveFormSelect
                            name="carrier"
                            label={t("CARRIER")}
                            options={getCarriers()}
                            control={control} />
                        <ReactiveFormRadioButtonSelect
                            control={control}
                            name="free_plan"
                            label={t("FREE_PLAN")}
                            options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
                        />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            label={t("START_DATE")}
                            name="start_date"
                        />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            label={t("END_DATE")}
                            name="end_date"
                        />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}