import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { IncomingMessage, NewMessage, NewTwilioConversation, TwilioConversation } from "../../models/TwilioConversation";
import { BurnerCall, JoinTwilioConferenceBody, StartTwilioConferenceBody, TwilioCall } from "../../models/TwilioCall";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { Device } from "twilio-client";
import { ConversationType } from "../../enums/ConversationType";

export const fetchVoiceToken = createAsyncThunk("voiceToken", async (identity:string) => {
    const response: AxiosResponse<string[]> = await (await axiosClient()).get(`/twilio/voice-token/${identity}`);
    return response.data
});

export const fetchChatToken = createAsyncThunk("chatToken", async (identity:string) => {
    const response: AxiosResponse<string[]> = await (await axiosClient()).get(`/twilio/chat-token/${identity}`);
    return response.data
});

export const fetchConversations = createAsyncThunk("conversations", async (identity:string) => {
    const response: AxiosResponse<TwilioConversation[]> = await (await axiosClient()).get(`/twilio/get-conversations/${identity}`);
    return response.data
});

export const fetchCalls = createAsyncThunk("calls", async (identity:string) => {
    const response: AxiosResponse<TwilioCall[]> = await (await axiosClient()).get(`/twilio/get-calls/${identity}`);
    return response.data
});

export const fetchConversationMessages = createAsyncThunk("conversations/messages", async (params: {conversationSid:string}) => {
    const response: AxiosResponse<TwilioConversation[]> = await (await axiosClient()).get(`/twilio/get-messages?conversationSid=${params.conversationSid}`);
    return {
        messages: response.data,
        sid: params.conversationSid
    }
});

export const createConversation = createAsyncThunk("conversations/new", async (params: {newConversation: NewTwilioConversation, conversationType: ConversationType}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<TwilioConversation> = await (await axiosClient()).post(`/twilio/create-conversation`, params.newConversation);
        return {...response.data, conversationType: params.conversationType};
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const sendMessage = createAsyncThunk("conversations/sendMessage", async (message: NewMessage, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<string> = await (await axiosClient()).post(`/twilio/create-message`, message);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const handleIncomingMessage = createAsyncThunk("conversations/incomingMessage", async (message: IncomingMessage) => {
    return message;
});

export const markMessageAsRead = createAsyncThunk("conversations/readMessage", async (conversationSid: string) => {
    return conversationSid;
});

export const setTwilioDeviceState = createAsyncThunk("twilioDevice/state", async (state: TwilioDeviceState) => {
    return state;
});

export const removeCurrentConversation = createAsyncThunk("twilioDevice/currentConversation", async () => {
    return true;
});

export const setTwilioDevice = createAsyncThunk("twilioDevice", async (device: Device) => {
    return device;
});

export const health = createAsyncThunk("twilioHealth", async () => {
    const response: AxiosResponse<boolean> = await (await axiosClient()).get(`/twilio/health`);
    return response.data
});

export const fetchPhoneContacts = createAsyncThunk("conversations/phoneContacts", async () => {
    const response: AxiosResponse<any> = await (await axiosClient()).get(`/twilio/get-phone-contacts`);
    return response.data
});

export const startClientConference = createAsyncThunk("calls/startClientConference", async (params: StartTwilioConferenceBody, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<TwilioConversation> = await (await axiosClient()).post(`/twilio/start-client-conference`, params);
        return { ...response.data };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const joinConference = createAsyncThunk("calls/joinConference", async (params: JoinTwilioConferenceBody, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<TwilioConversation> = await (await axiosClient()).post(`/twilio/join-conference`, params);
        return { ...response.data };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const setPhoneBurner = createAsyncThunk("phoneBurner", async (list: BurnerCall[]) => {
    return list;
});

export const callNextPhoneInBurner = createAsyncThunk("phoneBurner/nextCall", async () => {
    return true;
});

export const removePhoneFromBurner = createAsyncThunk("phoneBurner/removeCall", async (index: number) => {
    return index;
});

export const cleanPhoneBurner = createAsyncThunk("phoneBurner/clean", async () => {
    return true;
});

export const setStateLeadCreated = createAsyncThunk("phoneBurner/isLeadCreated", async (value: boolean) => {
    return value;
});