import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from "../../state/store";
import FilteredTable from "../../components/FilteredTable";
import { PolicySellsFilterModal } from "./PolicySellsFilterModal";
import { useEffect, useMemo, useState } from "react";
import { longDateFormat, shortDateFormat, shortLocalDateFormat } from "../../utils/functions";
import { RoleEnum, RoleLabel } from "../../enums/Role";
import { LoadingMask } from "../../components/LoadingMask";
import { clearPolicySells, fetchAllPolicySells, fetchPolicySells, pastPage } from "../../state/policySells/actions";
import moment from 'moment';
import Button from "../../components/Button";
import { DocumentArrowDownIcon, FunnelIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { PolicySell, PolicySellResponse } from "../../models/PolicySell";
import saveAs from "file-saver";
import * as xlsx from 'xlsx';
import classNames from "classnames";
import logo from '../../assets/images/asureis-side.png';
import { useNavigate } from "react-router-dom";
import { ProfilePath } from "../../constants/Routes";

interface Props {
}

export const PolicySellsReport = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const pagePolicies = useSelector((state: any) => state.policySells.pagePolicySells);
    const paginatedOptions = useSelector((state: any) => state.policySells.paginatedOptions);
    const policySells = useSelector((state: any) => state.policySells.policySells);
    const isLoading = useSelector((state: any) => state.policySells.isLoading);
    const error = useSelector((state: any) => state.policySells.error);
    const [isExportingToExcel, setIsExportingToExcel] = useState<boolean>(false);
    const dates = useSelector((state: any) => state.policySells.dates);
    const filters = useSelector((state: any) => state.policySells.filters);
    const navigate = useNavigate();

    const getLocalTimeZone = () => {
        const timeZoneOffset = new Date().getTimezoneOffset();

        if(timeZoneOffset > 0) {
            const hours = Math.floor(timeZoneOffset / 60);
            const minutes = timeZoneOffset % 60;
            const sHours = `-${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        } else {
            const hours = Math.floor(timeZoneOffset / 60) * -1;
            const minutes = timeZoneOffset % 60 * -1;
            const sHours = `+${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        }
    }

    useEffect(() => {
        if (currentUser) {
            
            const timeZone = getLocalTimeZone();

            const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
            const startDate = moment(endDate).hours(0).minutes(0).seconds(0).toDate();

            const end = moment(endDate).format('YYYY-MM-DD');
            const start = moment(startDate).format('YYYY-MM-DD');

            dispatch(fetchPolicySells({ startDate: start, endDate: end, timeZone: timeZone, page: 0, pageSize: 10, filters: {}}));
        }
    }, [dispatch, currentUser]);

    const columns = useMemo(() => {
        const showColumns = [
            {
                Header: t('NUMBER'),
                Cell: (row: any) => `${row.row.original.number ?? "-"}`,
            },
            {
                Header: t('CLIENT_FIRST_NAME'),
                accessor: "client_first_name",
            },
            {
                Header: t('CLIENT_LAST_NAME'),
                accessor: "client_last_name",
            },
            {
                Header: t('CLIENT_PHONE'),
                accessor: "client_phone",
            },
            {
                Header: t('CLIENT_DATE_OF_BIRTH'),
                Cell: (row: any) => shortDateFormat(`${row.row.original.client_date_of_birth}`)
            },
            {
                Header: t('CARRIER'),
                Cell: (row: any) => t(`${row.row.original.carrier}`),
            },
            {
                Header: t('EFFECTIVE_START_DATE'),
                Cell: (row: any) => shortDateFormat(`${row.row.original.effective_start_date}`),
            },
            {
                Header: t('MEMBERS_INSURED'),
                Cell: (row: any) => `${row.row.original.members_insured ?? "-"}`,
            },
            {
                Header: t('FREE_PLAN'),
                Cell: (row: any) => row.row.original.free_plan == '1' ? t('YES') : t('NO'),
            },
            {
                Header: t('AGENT'),
                accessor: "agent",
            },
            {
                Header: t('AGENT_PHONE'),
                accessor: "agent_phone",
            },
            {
                Header: t('STATUS'),
                Cell: (row: any) => t('COMPLETED'),
            },
            {
                Header: t('CREATION_DATE'),
                Cell: (row: any) => shortLocalDateFormat(`${row.row.original.creation_date}`),
            }
        ];

        /*const actions = {
            Header: " ",
            Cell: (row: any) => {
                return <div className="flex flex-row justify-center divide-x">
                    <Button
                        isTerciary
                        tooltip={t("UPDATE_POLICY")}
                        iconOnly
                        onClick={() => openUpdatePolicyModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                    </Button>
                </div>
            }
        };*/
        return [...showColumns];

    }, [t, currentUser]);

    const getPaginationOptions = useMemo(() => {
        const timeZone = getLocalTimeZone();
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                 ? dispatch(pastPage())
                 : dispatch(fetchPolicySells({startDate: dates.startDate, endDate: dates.endDate, timeZone: timeZone, page: value, pageSize: paginatedOptions.pageSize, filters: filters}));
            },
            setPageSize: (value: number) => dispatch(fetchPolicySells({startDate: dates.startDate, endDate: dates.endDate, timeZone: timeZone, page: paginatedOptions.page, pageSize: value, filters: filters}))
        }
    }, [dispatch, paginatedOptions]);

    const exportReportToExcel = async() => {
        setIsExportingToExcel(true);
        const timeZone = getLocalTimeZone();

        const allData: any[] = [];
        policySells.forEach((policy: any) => {
            allData.push(...policy.pagePolicies);
        });

        if(allData.length == paginatedOptions.totalCount) {
            const exportData = allData.map((d: any) => {
                return {
                    number: d.number ?? "-",
                    client_first_name: d.client_first_name,
                    client_last_name: d.client_last_name,
                    client_phone: d.client_phone,
                    client_date_of_birth: shortDateFormat(`${d.client_date_of_birth}`),
                    effective_start_date: shortDateFormat(`${d.effective_start_date}`),
                    carrier: t(`${d.carrier}`),
                    members_insured: d.members_insured ?? "-",
                    free_plan: d.free_plan == '1' ? t('YES') : t('NO'),
                    agent: d.agent,
                    agent_phone: d.agent_phone,
                    creation_date: shortLocalDateFormat(`${d.creation_date}`)
                }
            });
            convertAndDowloadToExcel(exportData);
        } else {
            const offset = allData.length;
            const limit = (paginatedOptions.totalCount - allData.length) + 10;

            const result = await dispatch(fetchAllPolicySells({startDate: dates.startDate, endDate: dates.endDate, timeZone: timeZone, limit: limit, offset: offset, filters: filters})).unwrap();
            allData.push(...result);

            const exportData = allData.map((d: any) => {
                return {
                    number: d.number ?? "-",
                    client_first_name: d.client_first_name,
                    client_last_name: d.client_last_name,
                    client_phone: d.client_phone,
                    client_date_of_birth: shortDateFormat(`${d.client_date_of_birth}`),
                    effective_start_date: shortDateFormat(`${d.effective_start_date}`),
                    carrier: t(`${d.carrier}`),
                    members_insured: d.members_insured ?? "-",
                    free_plan: d.free_plan == '1' ? t('YES') : t('NO'),
                    agent: d.agent,
                    agent_phone: d.agent_phone,
                    creation_date: shortLocalDateFormat(`${d.creation_date}`)
                }
            });
            convertAndDowloadToExcel(exportData);
        }
    }

    const convertAndDowloadToExcel = (data: any[]) => {
        let Heading = [[
            t('NUMBER'), t('CLIENT_FIRST_NAME'), t('CLIENT_LAST_NAME'), t('CLIENT_PHONE'), t('CLIENT_DATE_OF_BIRTH'),
            t('EFFECTIVE_START_DATE'), t('CARRIER'), t('MEMBERS_INSURED'), t('FREE_PLAN'),
            t('AGENT'), t('AGENT_PHONE'), t('CREATION_DATE')
        ]];

        //Had to create a new workbook and then add the header
        const wb = xlsx.utils.book_new();
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet([]);
        xlsx.utils.sheet_add_aoa(ws, Heading);

        //Starting in the second row to avoid overriding and skipping headers
        xlsx.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

        const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `PoliciesSellsReport-${Date.now()}.xlsx`);
        
        setIsExportingToExcel(false);
    };
    
    return(<>
        <div className='bg-zinc-100 min-h-screen w-full'>
            {isLoading && <LoadingMask />}
            {isExportingToExcel && <LoadingMask />}
            <div className={
                classNames({
                    "bg-white text-zinc-500": true, // colors
                    "flex items-center": true, // layout
                    "w-screen md:w-full sticky z-10 px-4 shadow-sm h-[70px] top-0 ": true, //positioning & styling
                })
            }>
                <img src={logo} alt="" className='h-6 mr-12' />
                <div className="font-bold text-lg mr-auto">{t("POLICY_SALES_REPORT")}</div>
                <div className="md:flex flex-row items-center gap-2 mr-2 cursor-pointer" onClick={() => navigate(ProfilePath)}>
                    <div className="hidden md:block">
                        <div className="text-end text-blue font-medium">{`${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`}</div>
                        <div className="text-end">{RoleLabel(currentUser?.role_id ?? "")}</div>
                    </div>
                    <div className="relative flex justify-center items-center">
                        <UserCircleIcon className="h-8  aspect-square" />
                    </div>
                </div>
            </div>
            <div className='w-full pt-5 pb-10'>
                <FilteredTable
                    columns={columns}
                    data={pagePolicies}
                    isPaginated
                    paginationOptions={getPaginationOptions}
                    hasSearchBar={false}
                    secondaryActionButton={<Button onClick={() => exportReportToExcel()}>
                        <span className='flex items-center font-semibold pr-3'>
                            <div className='w-8 p-1 aspect-square mr-2'><DocumentArrowDownIcon /></div>
                            {t('DOWNLOAD_REPORT')}
                        </span>
                    </Button>}
                    buttonAction={<Button onClick={() => setFilterModalOpen(true)} iconOnly classNames="w-9 p-1 aspect-square mr-4 ">
                        <FunnelIcon className=""></FunnelIcon></Button>}
                />
            </div>
            <PolicySellsFilterModal isFilterModalOpen={isFilterModalOpen} setFilterModalOpen={() => setFilterModalOpen(false)} />
        </div>
    </>)
}