import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon, PaperAirplaneIcon, PhoneIcon, PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import phoneBg from '../../assets/images/phone-bg.png';
import Button from "../../components/Button";
import { Badge } from "../../components/Calls/Badge";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import { Toast } from "../../components/Toast";
import { Form } from "../../components/form/Form";
import { FormSearchableSelect } from "../../components/form/FormSearchableSelect";
import { ReactiveFormPhoneInput } from "../../components/form/ReactiveFormPhoneInput";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { Client } from "../../models/Client";
import { ConversationFilter } from "../../models/ConversationFilter";
import { IncomingMessage, TwilioConversation } from "../../models/TwilioConversation";
import { AppDispatch } from "../../state/store";
import { createConversation, fetchConversationMessages, removeCurrentConversation, sendMessage } from "../../state/twilio/actions";
import { longDateFormat, messageDateFormat } from "../../utils/functions";
import { ConversationFilters } from "../../components/Calls/ConversationFilters";
import ClientContactForm from "../../components/ClientContactForm";
import LeadContactForm from "../../components/LeadContactForm";
import { ConversationType } from "../../enums/ConversationType";
import { FormSelect } from "../../components/form/FormSelect";

export interface MessageContent {
    text: string,
    sentByMe: boolean,
    date: Date | string,
    id: string
}

interface Props {
    phoneNames: Map<string, { name: string, id: number }>
    makePhoneCall: (phone: string) => void;
}

interface FormInput {
    newConversationNumber: string
}

export const Conversations = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [messageText, setMessageText] = useState("");
    const [filter, setFilter] = useState("");
    const [isNewConversationModalOpen, setIsNewConversationModalOpen] = useState(false);
    const twilio = useSelector((state: any) => state.twilio);
    const [collapsed, setCollapsed] = useState(true);
    const [selectedClientOnModal, setSelectedClientOnModal] = useState<number>();
    const { t } = useTranslation();
    const methods = useForm<FormInput>();
    const { handleSubmit, control, setValue } = methods;
    const [filters, setFilters] = useState<ConversationFilter>({ selectedTypes: [] });
    const [showFilters, setShowFilters] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        const ref = messagesEndRef.current as any;
        ref?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [twilio.currentConversation?.messages]);

    useEffect(() => {
        return (() => {
            dispatch(removeCurrentConversation());
        })
    }, [dispatch]);

    const selectedClientId = useMemo(() => {
        return (twilio.currentConversation?.friendlyName) ? props.phoneNames.get(twilio.currentConversation?.friendlyName)?.id : null;
    }, [props.phoneNames, twilio.currentConversation]);

    const openConversation = async (selectedConversation: TwilioConversation) => {
        dispatch(fetchConversationMessages({ conversationSid: selectedConversation.sid }));
    };

    const onSubmit: SubmitHandler<FormInput> = async (data: FormInput) => {
        const newNumber = data.newConversationNumber[0] === "+" ? data.newConversationNumber : "+" + data.newConversationNumber;
        dispatch(createConversation({ newConversation: { from: currentUser.main_twilio_number?.twilio_phone, to: newNumber }, conversationType: ConversationType.CLIENTS})).then((e) => {
            if (e.type === "conversations/new/rejected") {
                Toast(t(twilio.error?.reason) ?? t("SOMETHING_WENT_WRONG"), twilio.error.cause_info);
            } else {
                toast(t("CONVERSATION_CREATED_SUCCESSFULLY"));
            }
        })
        setValue("newConversationNumber", "");
        setSelectedClientOnModal(undefined);
        setIsNewConversationModalOpen(false);
    }

    const handleMessageText = (e: any) => {
        setMessageText(e.target.value);
    }

    const handleSendMessage = (e: any) => {
        e.preventDefault()
        if (twilio.currentConversation && twilio.areTwilioCredentialsValid) {
            dispatch(sendMessage({
                message: messageText,
                from: currentUser.main_twilio_number?.twilio_phone,
                conversationSid: twilio.currentConversation.sid
            })).then(() => setMessageText(""));
        }
    }

    const handleSearchChange = (event: any) => {
        setFilter(event.target.value);
    }

    const filteredConversations = useMemo(() => {
        let conversations = twilio.conversations;
        if (filters.selectedTypes.length > 0) {
            conversations = filters.selectedTypes.reduce((acc, element) => {
                const filteredConversations = twilio.conversations.filter((x: TwilioConversation) => `${x.conversationType}` === element);
                return acc.concat(filteredConversations);
            }, []);
        }
        conversations = [...conversations]?.sort((a: { dateUpdated: string | number | Date; }, b: { dateUpdated: string | number | Date; }) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
        if (!filter) {
            // If the filter is an empty string, return all conversations.
            return conversations;
        }

        // Convert the filter string to lowercase for case-insensitive comparison.
        const filterLowerCase = filter.toLowerCase();

        return conversations.filter((conversation: any) => {
            const conversationsInfo = props.phoneNames.get(conversation.friendlyName);

            // Convert the conversation values to lowercase for case-insensitive comparison.
            const friendlyNameLowerCase = conversation.friendlyName.toLowerCase();
            const fullName = `${conversationsInfo?.name}`.toLowerCase();

            return friendlyNameLowerCase.includes(filterLowerCase) || fullName.includes(filterLowerCase);
        });
    }, [twilio.conversations, filter, props.phoneNames, filters]);


    const getClients = useMemo(() => {
        return twilio?.phoneContacts?.clients?.filter((x: Client) => !twilio.conversations.find((y: TwilioConversation) => y.friendlyName === x.phone)).map((client: any) => {
            return {
                label: `${client.name}`,
                value: client.id,
                key: client.id,
                object: client
            }
        })
    }, [twilio?.phoneContacts, twilio.conversations]);

    const getLeads = useMemo(() => {
        return twilio?.phoneContacts?.leads?.filter((x: Client) => !twilio.conversations.find((y: TwilioConversation) => y.friendlyName === x.phone)).map((lead: any) => {
            return {
                label: `${lead.name}`,
                value: lead.id,
                key: lead.id,
                object: lead
            }
        })
    }, [twilio?.phoneContacts, twilio.conversations]);

    const handleNewConversationNumberChange = (event: any) => {
        setValue("newConversationNumber", twilio?.phoneContacts?.clients?.find((x: Client) => x.id === +event.target.value)?.phone ?? "")
        setSelectedClientOnModal(event.target.value)
    }

    const closeModal = () => {
        setIsNewConversationModalOpen(!isNewConversationModalOpen);
        setValue("newConversationNumber", "");
        setSelectedClientOnModal(undefined);
    }

    const typedInPhoneNumber = useWatch({
        control,
        name: "newConversationNumber", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    })

    useEffect(() => {
        if (!props.phoneNames.has(typedInPhoneNumber)) {
            setSelectedClientOnModal(undefined);
        }
    }, [props.phoneNames, typedInPhoneNumber])

    const NewConversationModal = () => {
        return <Modal isOpen={isNewConversationModalOpen} onClose={closeModal} label={t("NEW_CONVERSATION")} saveButton={{ label: t("CREATE"), icon: <PaperAirplaneIcon />, onClick: handleSubmit(onSubmit) }}>
            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                <div className="w-full mb-4">{t("SELECT_CLIENT_OR_TYPE_NUMBER")}</div>
                <FormSelect
                    className="md:w-1/2"
                    selectedValue={selectedClientOnModal ? `${selectedClientOnModal}` : undefined}
                    name="client_id"
                    handleOnChange={handleNewConversationNumberChange}
                    label={t("CLIENT")}
                    options={getClients}
                />
                <ReactiveFormPhoneInput
                    control={control}
                    className='md:w-1/2'
                    name="newConversationNumber"
                    label={t("PHONE")}
                    isRequired
                />
            </Form >
        </Modal >
    };

    const showOrHideFilters = useCallback(() => {
        setShowFilters(prev => !prev);
    }, []);

    const updateFilterValues = (fil: ConversationFilter) => {
        setFilters(fil);
    };

    return (
        <div className="w-full grow px-5 space-y-4 flex flex-col">
            {twilio.isLoading && <LoadingMask />}
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            {NewConversationModal()}
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="w-full grow flex flex-row overflow-hidden" style={{ height: 'calc(100vh - 164px)' }}>
                    <div className={classNames({
                        "overflow-x-auto lg:w-1/3 lg:border-r md:overflow-auto pr-5": true,
                        "block": collapsed || !twilio.currentConversation,
                        "hidden": !collapsed
                    })}>
                        <div className="w-full flex justify-between flex-wrap pb-2">
                            <h2 className="text-md font-semibold">Chats</h2>
                            <div className="flex">
                                <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary onClick={() => setIsNewConversationModalOpen(true)}>
                                    <PlusIcon className="w-6 text-gold" />
                                </Button>
                                <div className="relative flex justify-center lg:items-center gap-2 cursor-pointer">
                                    <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary onClick={showOrHideFilters}>
                                        <FunnelIcon className="w-6 text-gold" />
                                    </Button>
                                    {showFilters && <ConversationFilters filters={filters} updateFilterValues={updateFilterValues} />}
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-wrap pb-2">
                            <input
                                placeholder="Search ..."
                                className="bg-white opacity-90 py-2 px-4 rounded shadow w-full"
                                type="text"
                                onChange={handleSearchChange}
                                value={filter}
                            />
                        </div>
                        {filteredConversations?.map((conversation: TwilioConversation) => {
                            const unreadConversation = twilio.unreadMessages?.find((x: IncomingMessage) => x.conversationSid === conversation.sid)
                            return <div className={classNames({
                                "flex flex-row  gap-3 p-3 hover:bg-neutral-200 cursor-pointer shadow-sm hover:bg-gray": true,
                            })}
                                onClick={() => openConversation(conversation)}
                                key={conversation.sid}>
                                <div className="grow">
                                    <div className="flex flex-row justify-between">
                                        <div className="flex gap-2">
                                            <div className={"font-semibold"}>
                                                {conversation.friendlyName
                                                    ? props.phoneNames.get(conversation.friendlyName)
                                                        ? props.phoneNames.get(conversation.friendlyName)?.name
                                                        : conversation.friendlyName
                                                    : conversation.participant
                                                }
                                            </div>
                                            <Badge type={conversation.conversationType} />
                                        </div>
                                        <div className="text-sm font-light text-neutral-500">{messageDateFormat(conversation.dateUpdated)}</div>
                                    </div>
                                    <div className={classNames({
                                        "text-neutral-600 relative": true,
                                        "font-semibold": unreadConversation
                                    })}>
                                        {unreadConversation && <div className="bg-red-500 h-2 aspect-square rounded-full absolute top-1/2 right-0"></div>}
                                        {conversation?.lastMessage?.length > 50 ? `${conversation?.lastMessage?.substring(0, 50)}...` : `${conversation?.lastMessage ?? ''}`}
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>
                    {
                        twilio.currentConversation ?
                            <>
                                <div id="chatContainer" className={classNames({
                                    "flex flex-col relative w-full": true,
                                })}>
                                    <div className="text-l font-semibold py-2 px-5 flex" >
                                        <button
                                            className="grid text-asureis-gray place-content-center hover:text-white hover:bg-gold rounded-full opacity-0 md:opacity-100 p-1 mr-2"
                                            onClick={() => setCollapsed(!collapsed)}
                                        >
                                            {!collapsed ? <ChevronDoubleRightIcon className="w-4 h-4" /> : <ChevronDoubleLeftIcon className="w-4 h-4" />}
                                        </button>
                                        <div className="flex justify-between w-full items-center">
                                            {props.phoneNames.get(twilio.currentConversation.friendlyName ?? "")
                                                ? props.phoneNames.get(twilio.currentConversation.friendlyName ?? "")?.name
                                                : twilio.currentConversation.friendlyName
                                            }
                                            <Button isTerciary isDisabled={twilio.twilioDeviceState !== TwilioDeviceState.READY} iconOnly onClick={() => props.makePhoneCall(twilio.currentConversation.friendlyName)} classNames="pl-2"><PhoneIcon className="h-5 aspect-square stroke-gold" /></Button>
                                        </div>
                                    </div>
                                    <div className="overflow-auto flex flex-col relative md:overflow-auto h-full">
                                        {twilio.currentConversation?.messages?.map((message: any) => {
                                            const sentByMe = message.author === currentUser.main_twilio_number?.twilio_phone
                                            return <div className={classNames({
                                                "flex flex-row my-2 px-2": true,
                                                "justify-end": sentByMe
                                            })}
                                                key={message.sid}>
                                                <div className="grow flex flex-col justify-end">
                                                    <div className={classNames({
                                                        "p-3 rounded-b-xl w-fit max-w-lg mx-4": true,
                                                        "bg-blue-300 ml-auto rounded-tl-xl": sentByMe,
                                                        "bg-gold-200 rounded-tr-xl": !sentByMe
                                                    })}>
                                                        {message.body}
                                                        <div className="text-xs opacity-50 text-right">{longDateFormat(`${message.dateCreated}`)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <form onSubmit={handleSendMessage}>
                                        <div className="sticky bottom-0 w-full flex flex-row">

                                            <input placeholder="" value={messageText} onChange={handleMessageText} className="bg-gray-200 py-2 px-4 rounded shadow grow ml-2 mb-2"></input>
                                            <button disabled={!twilio.areTwilioCredentialsValid} type="submit" className="bg-blue rounded-full ml-2 w-10 aspect-square mr-2 mb-2"><PaperAirplaneIcon className="h-6 aspect-square fill-white stroke-blue mx-auto" /></button>

                                        </div>
                                    </form>
                                </div>
                                <div className={classNames({
                                    "flex flex-col relative w-full": true,
                                    "lg:w-1/3": collapsed,
                                    "lg:w-1/2": !collapsed,
                                })}>
                                    {twilio.currentConversation.conversationType === ConversationType.CLIENTS
                                        ? <ClientContactForm clientId={selectedClientId} />
                                        : <LeadContactForm leadId={selectedClientId} />
                                    }
                                </div>
                            </>
                            : twilio.areTwilioCredentialsValid
                                ? <div className="w-full lg:w-3/4 text-center relative mx-auto">
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        <img src={phoneBg} alt="Asureis Logo" className='h-32' />
                                    </div>
                                </div>
                                : <div className="text-l text-center relative inset-y-2/4 lg:w-3/4">{t("TWILIO_CONNECTION_ERROR")}</div>
                    }
                </div>
            </div>
        </div>
    )
}