export interface NewPolicy {
    id?:number | undefined
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    members_insured?: number;
    free_plan?: string;
    carrier_id?: number;
};

export const newPolicyInitialValues = {
    number: "",
}

export interface AllPolicies {
    pageNumber: number,
    pagePolicies: Policy[]
}

export interface PolicyResponse {
    policies: Policy[];
    page: number,
    totalPages: number,
    totalCount: number,
    pageSize: number,
    //filters?: ClientsFilters
}

export interface Policy {
    id: number;
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    carrier_id?: number;
    carrier?: string;
    client_id?: number;
    client?: string;
    agency_id?: number;
    agent_id?: number;
    agent?: string;
    lives?: number;
    commission_per_life?: number;
    agent_commission_rule_id?: number;
    members_insured?: number;
    free_plan?: string;
}

export interface EditPolicy {
    id?: number;
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    carrier_id?: number;
    members_insured?: number;
    free_plan?: string;
    delete_at?: Date;
}