import { ArrowLeftIcon, ArrowsRightLeftIcon, CheckIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import states from 'states-us';
import { Accordion } from '../../components/Accordion';
import Button from '../../components/Button';
import { LoadingMask } from '../../components/LoadingMask';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ReactiveFormPhoneInput } from '../../components/form/ReactiveFormPhoneInput';
import { ReactiveFormRadioButton } from '../../components/form/ReactiveFormRadioButton';
import { ReactiveFormSelect } from '../../components/form/ReactiveFormSelect';
import { ClientsPath } from '../../constants/Routes';
import { getCarriers } from '../../enums/Carrier';
import { ClientFormSectionEnum, getClientFormSections } from '../../enums/ClientFormSection';
import { getEmployeeTypes } from '../../enums/EmployeerType';
import { getGenders } from '../../enums/Gender';
import { getImmigrationTypes } from '../../enums/ImmigrationStatusType';
import { getMaritalStatuses } from '../../enums/MaritalStatus';
import { getRelationships } from '../../enums/Relationship';
import { getCountries } from '../../i18n/countries';
import { newAddressInitialValues } from '../../models/Address';
import { Client, NewClientModel } from '../../models/Client';
import { newDependentInitialValues } from '../../models/Dependant';
import { newIncomeSourceInitialValues } from '../../models/IncomeSource';
import { NewPolicy, newPolicyInitialValues } from '../../models/Policies';
import { createClient } from '../../state/clients/actions';
import { AppDispatch } from '../../state/store';
import { Tag, newTagResponseInitialValues } from '../../models/Tags';
import { RoleEnum } from '../../enums/Role';
import { Toast } from '../../components/Toast';
import { ReactiveFormRadioButtonSelect } from '../../components/form/ReactiveFormRadioButtonSelect';
import { ReactiveFormUploadFile } from '../../components/form/ReactiveFormUploadFile';
import { Attachment, AttachmentUrls, NewDocument } from '../../models/Document';
import { getFileSignedUrl } from '../../state/documents/actions';
import moment from 'moment';
import Select from "react-select";
import { Modal } from '../../components/Modal';

const initialOpenedSections: Map<ClientFormSectionEnum, boolean> = new Map();
initialOpenedSections.set(ClientFormSectionEnum.PERSONAL_DETAILS, true);
initialOpenedSections.set(ClientFormSectionEnum.ADDRESS, true);

export const NewClient = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openedSections, setOpenedSections] = useState<Map<ClientFormSectionEnum, boolean>>(initialOpenedSections);
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.clients.isLoading);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const clients = useSelector((state: any) => state.clients.clients);
  const error = useSelector((state: any) => state.clients.error);
  const tags = useSelector((state: any) => state.tags.tags);
  const users = useSelector((state: any) => state.users.users);
  const [agentTags, setAgentTags] = useState<Tag[]>([]);
  const [clientDocuments, setClientDocuments] = useState<any[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [currentAgents, setCurrentAgents] = useState<any>();
  const [assignToAgent, setAssignToAgent] = useState<any>();
  const [isAssignClientModalOpen, setIsAssignClientModalOpen] = useState(false);

  const methods = useForm<NewClientModel>({
    defaultValues: {
      country_of_birth: "US",
      income_sources: [newIncomeSourceInitialValues],
      policies: [newPolicyInitialValues],
      dependents: [newDependentInitialValues],
      addresses: [{
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip_code: "",
        is_main_address: true
      }],
      tags_responses: [],
      attachments: []
    }
  });
  const { handleSubmit, control, setValue } = methods;

  const { fields: dependents, append: appendDependent, remove: removeDependent } = useFieldArray({
    control,
    name: "dependents"
  });

  const { fields: addresses, append: appendAddress, remove: removeAddress } = useFieldArray({
    control,
    name: "addresses"
  });

  const { fields: incomeSources, append: appendIncomeSource, remove: removeIncomeSource } = useFieldArray({
    control,
    name: "income_sources"
  });

  const { fields: policies, append: appendPolicy, remove: removePolicy } = useFieldArray({
    control,
    name: "policies"
  });

  useEffect(() => {
    if (currentUser) {
      setValue("agent_id", currentUser.id)
    }
  }, [currentUser, setValue]);

  useEffect(() => {
    if (currentUser) {
      if(currentUser.role_id === RoleEnum.ADMIN) {
        const aTags = tags.filter((tag: any) => tag.user.id === currentUser.id || !tag.is_personal);
        setAgentTags(aTags);

        const initalTags = tags.map((tag: any) => {
          return {tag_id: tag.id, response: ""};
        });
        setValue("tags_responses", initalTags);
      } else {
        setAgentTags(tags);

        const initalTags = tags.map((tag: any) => {
          return {tag_id: tag.id, response: ""};
        });
        setValue("tags_responses", initalTags);
      }
    }

  }, [currentUser, setValue, tags, setAgentTags]);

  const agents = useMemo(() => {
    return users.map((x: any) => {
        return {
            label: `${x.first_name} ${x.last_name}`,
            value: x.id
        }
    })
  }, [users]);

  const getStates = useMemo(() => {
    return states.map(state => {
      return {
        label: state.name,
        value: state.abbreviation,
        key: state.abbreviation
      }
    })
  }, []);

  const handleClientDocumentsDrop = (e: any) => {
    for(let i = 0; i < e.length; i++) {
      setClientDocuments(prev => [...prev, e[i]]);
    }
  };

  const handleClientDocuments = (e: any) => {
    for(let i = 0; i < e.length; i++) {
      setClientDocuments(prev => [...prev, e[i]]);
    }
  }

  const handleRemoveClientDocument = (index: number) => {
    const list = [...clientDocuments];
    list.splice(index, 1);
    setClientDocuments(list);
  }

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const onSubmit: SubmitHandler<NewClientModel> = async (data: NewClientModel) => {
    if(checkInvalidDates(data)) {
      Toast(t("POLICY_EFFECTIVE_START_DATE_ERROR"), t("POLICY_EFFECTIVE_START_DATE_MESSAGE"));
    } else {
      if(clientDocuments.length > 0) {
        uploadFiles(data);
      } else {
        createClientSubmit(data, []);
      }
    }
  };

  const uploadFiles = async(data: NewClientModel) => {
    setIsUploadingFiles(true);

    const uploadPromises = clientDocuments.map(async (file) => {

      const nameSplit = file.name.split('.');
      const filename = `${nameSplit[0]}-${Date.now()}.${nameSplit[1]}`;

      const result = await dispatch(getFileSignedUrl({fileName: filename})).unwrap();

      const uploadResponse = await fetch(result.upload_url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      });

      if(uploadResponse.ok && uploadResponse.status == 200) {
        const attachment: NewDocument = {
          name: filename,
          size: `${file.size}`
        };

        return attachment;
      }

      return undefined;
    });

    // Esperar a que todas las subidas se completen
    const results = await Promise.all(uploadPromises);

    if(results.length > 0) {
      const attachaments: NewDocument[] = [];
      results.forEach((attach) => {
        if(attach != undefined) {
          attachaments.push(attach);
        }
      });

      setIsUploadingFiles(false);
      createClientSubmit(data, attachaments);
    } else {
      setIsUploadingFiles(false);
      createClientSubmit(data, []);
    }

  }

  const createClientSubmit = async(data: NewClientModel, clientAttachments: NewDocument[]) => {
    const createdData = data;

    const clientPolicies: NewPolicy[] = [];
    if(data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if(po.carrier_id != undefined) {
          clientPolicies.push({
            number: po.number,
            effective_start_date: po.effective_start_date,
            effective_end_date: po.effective_end_date,
            carrier_id: +po.carrier_id,
            members_insured: po.members_insured,
            free_plan: po.free_plan
          })
        }
      });
    }
    createdData.policies = clientPolicies;
    createdData.attachments = clientAttachments;

    if(assignToAgent != null) {
      createdData.assigned_to = assignToAgent.value;
    }

    dispatch(createClient(createdData)).then((e) => {
      if (e.type === "clients/createClient/rejected") {
        Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        navigate(ClientsPath)
      }
    })
    
  }

  const checkInvalidDates = (data: NewClientModel) => {
    let policiesInvalidDates = 0;
    const todayDate = moment().format("YYYY-MM-DD");

    if(data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if(po.carrier_id != undefined) {
          if(po.effective_start_date != undefined) {
            if(moment(po.effective_start_date).isBefore(todayDate)) {
              policiesInvalidDates++;
            }
          } else {
            policiesInvalidDates++;
          }
        }
      });
    }

    return policiesInvalidDates > 0;
  }

  const getClients = useMemo(() => {
    return clients.map((client: Client) => {
      return {
        label: `${client.first_name} ${client.last_name}`,
        value: `${client.first_name} ${client.last_name}`,
        key: client.id
      }
    })
  }, [clients]);

  const openAssignClientModal = () => {
    //setSelectedDocument(envelope);
    setIsAssignClientModalOpen(true);
  };

  const closeAssignClientModal = () => {
    setIsAssignClientModalOpen(false);
  };

  const assignClientModal = () => {
    return <Modal isOpen={isAssignClientModalOpen} onClose={closeAssignClientModal} label={t("ASSIGN_CLIENT_TITLE")} saveButton={{ label: t("ASSIGN"), icon: <ArrowsRightLeftIcon />, onClick: setAssignedClientTo }}>
      <div className='w-full pt-3 pb-5'>
        <div className={"w-full px-3"}>
          <Select
              isSearchable
              isClearable
              isLoading={agents.length === 0}
              onChange={(inputValue: any) => {
                if(inputValue == null) {
                  setAssignToAgent(null);
                }
                setCurrentAgents(inputValue)
              }}
              name="agent"
              options={agents}
              value={currentAgents}
              placeholder="Select agent"
              className="basic-single"
              classNamePrefix="select"
              maxMenuHeight={200}
              classNames={{
                  control: (state) =>
                      'bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]',
              }}
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'grey-50',
                  }),
              }}
          />
          <label className="text-gray-600 text-xs mb-5">
              {t("ASSIGN_CLIENT_MESSAGE")}
          </label>
        </div>
      </div>
    </Modal>
  };

  const setAssignedClientTo = () => {
    closeAssignClientModal();
    console.log(currentAgents);
    setAssignToAgent(currentAgents);
  }

  const PersonalDetailsSection = useMemo(() => {
    return <div className="w-full flex flex-wrap">
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("FIRST_NAME")}
        name="first_name"
        isRequired />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("MIDDLE_NAME")}
        name="middle_name"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("LAST_NAME")}
        name="last_name"
        isRequired />
      <ReactiveFormInput
        control={control}
        type='date'
        className='md:w-1/4'
        label={t("DATE_OF_BIRTH")}
        name="date_of_birth"
        isRequired />
      <ReactiveFormSelect
        className="md:w-1/4"
        name="country_of_birth"
        label={t("COUNTRY_OF_BIRTH")}
        options={getCountries()}
        control={control}
        isRequired />
      <ReactiveFormSelect
        className="md:w-1/4"
        name="gender_id"
        label={t("GENDER")}
        options={getGenders()}
        control={control} 
      />
      <ReactiveFormSelect
        className="md:w-1/4"
        name="marital_status_id"
        label={t("MARITAL_STATUS")}
        options={getMaritalStatuses()}
        control={control}
      />
      <ReactiveFormPhoneInput
        control={control}
        className='md:w-1/4'
        name="phone"
        label={t("PHONE")}
        isRequired
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("EMAIL")}
        name="email"
        type='email'
        isRequired />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("HEIGHT")}
        name="height"
        type='number'
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("WEIGHT")}
        name="weight"
        type='number'
      />
      <ReactiveFormSelect
        control={control}
        className={"w-full px-3 md:w-1/3"}
        name="referred_by"
        label={t("REFERENCE")}
        options={getClients}
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("DOCTOR_FULL_NAME")}
        name="doctor_full_name"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("DOCTOR_CITY")}
        name="doctor_city"
      />
      <ReactiveFormRadioButton
        className={"w-full px-3 md:w-1/3"}
        name="tobacco"
        label={t("4_OR_MORE_TOBACCO")}
        control={control}
        options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
      />
      <ReactiveFormRadioButton
        className={"w-full px-3 md:w-1/3"}
        name="po_box"
        label={t("PO_BOX")}
        control={control}
        options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
      />
      <ReactiveFormRadioButton
        className={"w-full px-3 md:w-1/3"}
        name="file_tax_return"
        label={t("WILL_YOU_FILE_TAX_RETURN")}
        control={control}
        options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
      />
      {
        agentTags.length > 0 &&
        <div className='w-full'>
          <div className='text-lg font-semibold py-3 px-3 w-full'>{t("ADDITIONAL_INFORMATION")}</div>
          <div className="w-full flex flex-wrap gap-y-4">
            {
              agentTags.map((tag, index) => {
                return <ReactiveFormInput
                    control={control}
                    className='md:w-1/3'
                    label={tag.label}
                    name={`tags_responses.${index}.response`}
                  />
              })
            }
          </div>
        </div>
      }
    </div>
  }, [control, getClients, t]);

  const AddressesSection = useMemo(() => {
    return <div className="w-full flex flex-wrap gap-y-4">
      {addresses?.sort(x => Number(x.is_main_address)).map((source, index) => {
        return <Fragment key={source.id}>
          <div className={classNames({
            "w-full flex flex-wrap": true,
            "border-b pb-5 mb-4 border-slate-300": index + 1 !== addresses.length
          })}>
            {source.is_main_address && <div className='text-lg font-semibold pb-3 px-3 w-full'>{t("MAIN_ADDRESS")}</div>}
            <ReactiveFormInput
              control={control}
              className='md:w-1/2'
              label={t("STREET_ADDRESS")}
              name={`addresses.${index}.address_line_1`}
            />
            <ReactiveFormInput
              control={control}
              className='md:w-1/2'
              label={t("STREET_ADDRESS2")}
              name={`addresses.${index}.address_line_2`}
            />
            <ReactiveFormInput
              control={control}
              className='md:w-1/3'
              label={t("CITY")}
              name={`addresses.${index}.city`}
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`addresses.${index}.state`}
              label={t("STATE")}
              options={getStates}
              control={control} />

            <div className='flex md:w-1/3'>
              <ReactiveFormInput
                control={control}
                className='mr-2'
                label={t("ZIP_CODE")}
                name={`addresses.${index}.zip_code`}
              />
              <div className='self-end mb-3 mr-3'>
                {+index === 0
                  ? <Button iconOnly classNames='h-10 w-10' onClick={() => appendAddress(newAddressInitialValues)}><PlusIcon className="h-4 w-4 ml-1" /></Button>
                  : <Button iconOnly classNames='h-10 w-10' onClick={() => removeAddress(index)}><TrashIcon className="h-4 w-4 ml-1" /></Button>
                }
              </div>
            </div>
          </div>
        </Fragment>
      })}
    </div>
  }, [addresses, t, control, getStates, appendAddress, removeAddress]);

  const ImmigrationStatusSection = useMemo(() => {
    return <div className="w-full flex flex-wrap">
      <ReactiveFormSelect
        className="md:w-1/3"
        name="immigration_status.immigration_status_type_id"
        label={t("IMMIGRATION_STATUS_TYPE")}
        options={getImmigrationTypes()}
        control={control} />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("PASSPORT_NUMBER")}
        name="immigration_status.passport_number"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/3'
        label={t("USCIS_NUMBER")}
        name="immigration_status.uscis_number"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("SOCIAL_SECURITY_NUMBER")}
        name="immigration_status.social_security_number"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("SSN_ISSUE_DATE")}
        name="immigration_status.ssn_issue_date"
        type="date"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("GREEN_CARD_NUMBER")}
        name="immigration_status.green_card_number"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("GC_EXPIRATION_DATE")}
        name="immigration_status.gc_expiration_date"
        type="date"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("WORK_PERMIT_CARD")}
        name="immigration_status.work_permit_card"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("WPC_EXPIRATION_DATE")}
        name="immigration_status.wpc_expiration_date"
        type="date"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("DRIVER_LICENSE")}
        name="immigration_status.driver_license"
      />
      <ReactiveFormInput
        control={control}
        className='md:w-1/4'
        label={t("DL_EXPIRATION_DATE")}
        name="immigration_status.dl_expiration_date"
        type="date"
      />
    </div>
  }, [control, t]);

  const IncomeSourcesSection = useMemo(() => {
    return <div className="w-full flex flex-wrap">
      {incomeSources?.map((source, index) => {
        return <Fragment key={source.id}>
          <ReactiveFormSelect
            className="md:w-1/5"
            name={`income_sources.${index}.employee_type_id`}
            label={t("EMPLOYEE_TYPE")}
            options={getEmployeeTypes()}
            control={control} />
          <ReactiveFormInput
            control={control}
            className='md:w-1/5'
            label={t("EMPLOYER_NAME")}
            name={`income_sources.${index}.employer_name`}
          />
          <ReactiveFormPhoneInput
            control={control}
            className='md:w-1/5'
            label={t("PHONE")}
            name={`income_sources.${index}.phone_number`}
          />
          <ReactiveFormInput
            control={control}
            className='md:w-1/5'
            label={t("OCCUPATION")}
            name={`income_sources.${index}.occupation`}
          />
          <div className='flex md:w-1/5'>
            <ReactiveFormInput
              control={control}
              type='number'
              className='mr-2'
              label={t("INCOME")}
              name={`income_sources.${index}.income`}
            />
            <div className='self-end mb-3'>
              {+index === 0
                ? <Button iconOnly classNames='h-10 w-10' onClick={() => appendIncomeSource(newIncomeSourceInitialValues)}><PlusIcon className="h-4 w-4 ml-1" /></Button>
                : <Button iconOnly classNames='h-10 w-10' onClick={() => removeIncomeSource(index)}><TrashIcon className="h-4 w-4 ml-1" /></Button>
              }
            </div>
          </div>
        </Fragment>
      })}
    </div>
  }, [incomeSources, t, control, appendIncomeSource, removeIncomeSource]);

  const PoliciesSection = useMemo(() => {
    return <div className="w-full flex flex-wrap">
      {policies?.map((source, index) => {
        return <Fragment key={source.id}>
          <ReactiveFormInput
            control={control}
            className='md:w-1/3'
            label={t("NUMBER")}
            name={`policies.${index}.number`}
          />
          <ReactiveFormSelect
            className="md:w-1/3"
            name={`policies.${index}.carrier_id`}
            label={t("CARRIER")}
            options={getCarriers()}
            control={control} />
          <ReactiveFormInput
            control={control}
            type='date'
            className='md:w-1/3'
            label={t("START_DATE")}
            name={`policies.${index}.effective_start_date`}
          />
          <ReactiveFormInput
            control={control}
            type='date'
            className='md:w-1/3'
            label={t("END_DATE")}
            name={`policies.${index}.effective_end_date`}
          />
          <ReactiveFormInput
            control={control}
            className='md:w-1/3'
            label={t("MEMBERS_INSURED")}
            name={`policies.${index}.members_insured`}
            type="number"
          />
          <div className='flex md:w-1/3'>
            <ReactiveFormRadioButtonSelect
              className='mr-2'
              control={control}
              name={`policies.${index}.free_plan`}
              label={t("FREE_PLAN")}
              options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
            />
            <div className='self-end mb-3'>
              {+index === 0
                ? <Button iconOnly classNames='h-10 w-10' onClick={() => appendPolicy(newPolicyInitialValues)}><PlusIcon className="h-4 w-4 ml-1" /></Button>
                : <Button iconOnly classNames='h-10 w-10' onClick={() => removePolicy(index)}><TrashIcon className="h-4 w-4 ml-1" /></Button>
              }
            </div>
          </div>
        </Fragment>
      })}
    </div>
  }, [policies, control, t, appendPolicy, removePolicy]);

  const DependentsSection = useMemo(() => {
    return <div className="w-full flex flex-wrap gap-y-4">
      {dependents?.map((source, index) => {
        return <Fragment key={source.id}>
          <div className={classNames({
            "w-full flex flex-wrap": true,
            "border-b pb-5 mb-5 border-slate-300": index + 1 !== dependents.length
          })}>
            <ReactiveFormInput
              control={control}
              className='md:w-1/3'
              label={t("FULL_NAME")}
              name={`dependents.${index}.name`}
            />
            <ReactiveFormInput
              control={control}
              className='md:w-1/3'
              label={t("SOCIAL_SECURITY_NUMBER")}
              name={`dependents.${index}.social_security_number`}
            />
            <ReactiveFormInput
              control={control}
              type='email'
              className='md:w-1/3'
              label={t("EMAIL")}
              name={`dependents.${index}.email`}
            />
            <ReactiveFormPhoneInput
              control={control}
              className='md:w-1/4'
              label={t("PHONE")}
              name={`dependents.${index}.phone`}
            />
            <ReactiveFormInput
              control={control}
              type='date'
              className='md:w-1/4'
              label={t("DATE_OF_BIRTH")}
              name={`dependents.${index}.date_of_birth`}
            />
            <ReactiveFormSelect
              className="md:w-1/4"
              name={`dependents.${index}.relationship_id`}
              label={t("RELATIONSHIP")}
              options={getRelationships()}
              control={control} />

            <div className='flex md:w-1/4'>
              <ReactiveFormSelect
                className="mr-2"
                name={`dependents.${index}.gender_id`}
                label={t("GENDER")}
                options={getGenders()}
                control={control}
              />
              <div className='self-end mb-3 mr-3'>
                {+index === 0
                  ? <Button iconOnly classNames='h-10 w-10' onClick={() => appendDependent(newDependentInitialValues)}><PlusIcon className="h-4 w-4 ml-1" /></Button>
                  : <Button iconOnly classNames='h-10 w-10' onClick={() => removeDependent(index)}><TrashIcon className="h-4 w-4 ml-1" /></Button>
                }
              </div>
            </div>
          </div>
        </Fragment>
      })}
    </div>
  }, [dependents, control, t, appendDependent, removeDependent]);

  const DocumentsSection = useMemo(() => {
    return <div className="w-full">
      <div className='pb-5'>
        <ReactiveFormUploadFile 
          control={control}
          className='w-full'
          label={t("UPLOAD_DOCUMENTS")}
          name="client_documents"
          handleOnChange={handleClientDocuments}
          handleOnDrop={handleClientDocumentsDrop}/>
      </div>
      {
        clientDocuments.map((attach, index) => {
            return (
                <Fragment key={attach.name}>
                    <div className="w-full px-4 mb-3">
                        <div className="flex justify-between">
                          <div className='self-center'>
                            <label className="text-sm self-center">{attach.name}</label>
                            <label className="text-gray-600 text-sm self-center pl-3">{formatBytes(attach.size)}</label>
                          </div>
                          <Button iconOnly classNames='h-7 w-7 self-end' onClick={() => handleRemoveClientDocument(index)}><TrashIcon className="h-3 w-3" /></Button>
                        </div>
                        <hr className="mt-3"></hr>
                    </div>
                </Fragment>
            )
        })
      }
    </div>
  }, [handleClientDocuments, handleClientDocumentsDrop, handleRemoveClientDocument, t]);

  const getSectionInfo = useCallback((key: ClientFormSectionEnum) => {
    switch (key) {
      case ClientFormSectionEnum.PERSONAL_DETAILS:
        return PersonalDetailsSection;
      case ClientFormSectionEnum.ADDRESS:
        return AddressesSection;
      case ClientFormSectionEnum.IMMIGRATION_STATUS:
        return ImmigrationStatusSection;
      case ClientFormSectionEnum.INCOME_SOURCES:
        return IncomeSourcesSection;
      case ClientFormSectionEnum.POLICIES:
        return PoliciesSection;
      case ClientFormSectionEnum.DEPENDENTS:
        return DependentsSection;
      case ClientFormSectionEnum.DOCUMENTS:
        return DocumentsSection;
    }

  }, [PersonalDetailsSection, AddressesSection, ImmigrationStatusSection, IncomeSourcesSection, PoliciesSection, DependentsSection, DocumentsSection]);

  const getBlocks = useMemo(() => {
    return getClientFormSections().filter(x => x.key !== ClientFormSectionEnum.CONSENT_DOCUMENTS
      && x.key !== ClientFormSectionEnum.NOTES
    ).map(section => {
      const key = section.key as ClientFormSectionEnum
      const currentSection = openedSections.get(key);
      const updatedSections = new Map(openedSections); // Create a new Map
      return {
        label: section.label,
        key: section.key,
        isOpen: currentSection,
        handleChange: () => {
          updatedSections.set(key, !currentSection); // Update the new Map
          setOpenedSections(updatedSections); // Set the new Map
        },
        info: getSectionInfo(key)
      }
    })
  }, [openedSections, getSectionInfo]);

  return (
    <div>
      {isLoading && <LoadingMask />}
      {isUploadingFiles && <LoadingMask />}
      {assignClientModal()}
      <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
      <Form handleOnSubmit={handleSubmit(onSubmit)}>
        <Accordion blocks={getBlocks} />
        <div className='px-5 pb-5 justify-between w-full flex'>
          <Button isTerciary onClick={() => navigate(ClientsPath)}>
            <span className='flex items-center font-semibold pr-3'>
              <div className='w-8 p-1 aspect-square mr-2'><ArrowLeftIcon /></div>
              {t('RETURN')}
            </span>
          </Button>
          <div className='flex flex-row'>
            <div className='flex flex-col mr-5'>
              <label className='grid justify-items-end text-gray-600 text-sm'>{`${t('CREATED_BY')}:`}</label>
              <label className='grid justify-items-end underline cursor-pointer text-sky-600'
                onClick={openAssignClientModal}>
                  { 
                    assignToAgent != null
                    ? `${assignToAgent?.label ?? ""}`
                    : `${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`
                  }
                </label>
            </div>
            <Button onClick={handleSubmit(onSubmit)}>
              <span className='flex items-center font-semibold pr-3'>
                <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                {t('SAVE')}
              </span>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};