import i18next from "i18next";

export enum CarrierEnum {
    AETNA = 1,
    AMBETTER,
    CIGNA,
    OSCAR,
    BLUE_CROSS_TX,
    CHRISTUS,
    SCOTT_AND_WHITE,
    BLUE_CROSS_OK,
    ANTHEM,
    CARESOURCE,
    MEDICA,
    MOLINA,
}

export const CarrierLabel = (key: string) => ({
    [CarrierEnum.AETNA]: i18next.t('AETNA'),
    [CarrierEnum.AMBETTER]: i18next.t('AMBETTER'),
    [CarrierEnum.CIGNA]: i18next.t('CIGNA'),
    [CarrierEnum.OSCAR]: i18next.t('OSCAR'),
    [CarrierEnum.BLUE_CROSS_TX]: i18next.t('BLUE_CROSS_TX'),
    [CarrierEnum.CHRISTUS]: i18next.t('CHRISTUS'),
    [CarrierEnum.SCOTT_AND_WHITE]: i18next.t('SCOTT_AND_WHITE'),
    [CarrierEnum.BLUE_CROSS_OK]: i18next.t('BLUE_CROSS_OK'),
    [CarrierEnum.ANTHEM]: i18next.t('ANTHEM'),
    [CarrierEnum.CARESOURCE]: i18next.t('CARESOURCE'),
    [CarrierEnum.MEDICA]: i18next.t('MEDICA'),
    [CarrierEnum.MOLINA]: i18next.t('MOLINA')
})[key];

export const getCarriers = () =>
    Object.values(CarrierEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        let a =  {
            key: element,
            value: value,
            label: CarrierLabel(value) ?? ""
        }
        return a
    });