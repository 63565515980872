import {
    ChatBubbleBottomCenterIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    PencilIcon,
    PhoneIcon,
    PlayIcon,
    TrashIcon,
    UserPlusIcon,
    ArrowRightIcon
} from "@heroicons/react/24/outline";
import { ChangeEvent, createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import {
    useNavigate,
} from "react-router-dom";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from '../../components/LoadingMask';
import { ConversationsPath, NewLeadPath, UpdateLeadPath } from '../../constants/Routes';
import { LeadStatusEnum, LeadStatusLabel } from "../../enums/LeadStatus";
import { Modal } from "../../components/Modal";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { useForm } from "react-hook-form";
import * as xlsx from 'xlsx';
import { Lead, NewUploadLead } from "../../models/Lead";
import { AppDispatch } from "../../state/store";
import { ToastContainer, toast } from "react-toastify";
import { startLeadAutomation, uploadLeads } from "../../state/leads/actions";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { Toast } from '../../components/Toast';
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { createConversation, fetchConversationMessages, fetchConversations } from "../../state/twilio/actions";
import { ConversationType } from "../../enums/ConversationType";
import uploadLeadTemplate from '../../assets/templates/uploadLeadTemplate.xlsx';
import stringSimilarity from 'string-similarity';
import { FormSelect } from "../../components/form/FormSelect";
import { generateComissionsReport, getComissionsReport, pastPage } from "../../state/commissions/action";
import { Commission } from "../../models/Commission";
import moment from "moment";


interface Props {}

export const Commissions = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: any) => state.commissions.isLoading);
    const [isOpenFilePreviewModal, setIsOpenFilePreviewModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const inputFile: RefObject<HTMLInputElement> = createRef()
    const commissions = useSelector((state: any) => state.commissions.state);
    const commissionsError = useSelector((state: any) => state.commissions.error);
    const billingPeriods = useSelector((state: any) => state.agencies.billingPeriods)
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('confirmed')

    const pageCommissions = useSelector((state: any) => state.commissions.pageCommissions);
    const paginatedOptions = useSelector((state: any) => state.commissions.paginatedOptions);

    const methods = useForm<{}>();
    const { control } = methods;

    const startMethods = useForm<{}>();
    const { handleSubmit: handleStartSubmit, control: startControl } = startMethods;

    const columns = useMemo(
        () => [
            {
                Header: t('POLICY_NUMBER'),
                accessor: "policy_number",
            },
            {
                Header: t('CARRIER'),
                accessor: "carrier"
            },
            {
                Header: t('BILLING_PERIOD'),
                accessor: "billing_period"
            },
            {
                Header: t('COMMISSION_TYPE'),
                accessor: "commission_rule_type"
            },
            {
                Header: t('COMMISSION_TYPE_VALUE'),
                accessor: "commission_rule_type_value"
            },
            {
                Header: t('AGENT'),
                accessor: "agent",
            },
            {
                Header: t('INCOME'),
                accessor: "total_income"
            },
            {
                Header: t('AGENT_COMMISSION'),
                accessor: "agent_commission"
            },
            {
                Header: t('AGENCY_COMMISSION'),
                accessor: "agency_commission"
            },
        ],
        [t]
    );

    const unmatchedColumns = useMemo(
        () => [
            {
                Header: t('POLICY_NUMBER'),
                accessor: "policy_number",
            },
            {
                Header: t('CARRIER'),
                accessor: "carrier"
            },
            {
                Header: t('BILLING_PERIOD'),
                accessor: "billing_period"
            },
            {
                Header: t('AGENT'),
                accessor: "agent",
            },
            {
                Header: t('INCOME'),
                accessor: "total_income"
            },
            {
                Header: t('UNMATCH_REASON'),
                accessor: "unmatch_reason",
                Cell: (data: any) => <>{t(data.row.original.unmatch_reason)}</>
            },
        ],
        [t]
    );

    const openFilePreviewModal = () => {
        setIsOpenFilePreviewModal(true);
    };

    const closeFilePreviewModal = () => {
        setIsOpenFilePreviewModal(false);
        setSelectedFile(null);
        inputFile.current!.value = ''
    };

    const onFileUploadSubmitted = () => {
        let matches = selectedFile.format;

        const data = selectedFile.fileData.map((obj: any) =>
            matches.reduce((acc: any, m: any) => {
                if (m.match.key in obj) {
                    acc[m.key] = obj[m.match.key];  // Only add the keys that exist in the object
                }
                return acc;
            }, {})
        );

        dispatch(generateComissionsReport({agencyId: currentUser.agency_id, body: data})).then(e => {
            if (e.type === "commissions/generateComissionsReport/rejected") {
                Toast(t("SOMETHING_WENT_WRONG"), commissionsError.cause_info);
            } else {
                toast(t("COMMISSIONS_UPLOADED_SUCCESFULLY"));
                setSelectedStatus('confirmed')
            }
        })

        closeFilePreviewModal()
    }


    const exportReport = () => {
        const wb = xlsx.utils.book_new();

        // Convert HTML table to a worksheet
        const ws = xlsx.utils.json_to_sheet(commissions);

        // Append the worksheet to the workbook
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate and download the XLSX file
        xlsx.writeFile(wb, 'table.xlsx');
    }

    const showFilePreviewModal = () => {
        return <Modal 
            isOpen={isOpenFilePreviewModal} 
            onClose={closeFilePreviewModal} 
            label={t('UPLOAD_COMMISIONS')} 
            saveButton={{ label: t("UPLOAD"), icon: <DocumentArrowUpIcon />, onClick: onFileUploadSubmitted}}>
            
            <label className="text-sm text-slate-600">{t("EXPECTED_COMMISSION_FORMAT")}</label>

            {selectedFile && <div className="flex flex-col divide-y mt-5">
                {selectedFile.format.map((x:any) => <div className="flex grow justify-between">
                    <div className='md:w-1/2 content-center'>
                        <label>{x.header}</label>
                    </div>

                    <FormSelect
                            label=""
                            name=""
                            options={ selectedFile.fileColumns.map((item: any) => ({ label: item, value: item })) }
                            selectedValue={x.match.key}
                            handleOnChange={(e) => {
                                setSelectedFile((prev: any) => {
                                    let idx = prev.format.indexOf((y:any ) => y.key === x.key)

                                    x.match.key = e.target.value;
                                    x.match.score = 2

                                    prev.format[idx] = x;

                                    return {...prev}
                                    
                                })
                            }}
                        />
                </div>)}
            </div>}

        </Modal>
    }

    const showUploadDialog = () => {
        inputFile.current!.click();
    }

    const findBestMatches = (source1Keys: {key: string, header: string}[], source2Keys: string[], threshold = 0.5) => {
        const matches: any = {};
      
        const fileMatchedKeys: any = {}

        source1Keys.forEach(key1 => {
          const bestMatch = stringSimilarity.findBestMatch(key1.header, source2Keys);

          const bestMatchKey = bestMatch.bestMatch.target;
          const bestMatchRating = bestMatch.bestMatch.rating;
      
          if (bestMatchRating >= threshold) {
            if (!fileMatchedKeys[bestMatchKey]) {

                matches[key1.key] = { key: bestMatchKey, score: bestMatchRating }
                fileMatchedKeys[bestMatchKey] = { key: key1, score: bestMatchRating }
            } else {
                let prevValue = fileMatchedKeys[bestMatchKey]

                if (prevValue.score < bestMatchRating) {
                    matches[key1.key] = { key: bestMatchKey, score: bestMatchRating }
                    matches[prevValue.key] = { key: null, score: 0}
                    fileMatchedKeys[bestMatchKey] = bestMatchRating
                }
            }
          }
        });
      
        return matches;
    };

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0]

        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            const data = e?.target?.result;

            let workbook = xlsx.read(data)

            let requiredFields = [
                {key: 'policy_number', header: t("POLICY_NUMBER") },
                {key: 'billing_date', header: t("BILLING_DATE") },
                {key: 'commission', header: t("COMMISSION") },
                {key: 'carrier_name', header: t("CARRIER") },
                {key: 'agent_name', header: t("AGENT_NAME") },
            ]

            let fileData = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

            let ds2 = Object.keys(fileData.at(0) as any);

            let matches = findBestMatches(requiredFields, ds2)

            let format = requiredFields.map(x => {
                return {
                    ...x,
                    match: matches[x.key]
                }
            })
            
            setSelectedFile({
                fileData: fileData,
                format: format,
                fileColumns: ds2,
            })

            openFilePreviewModal()
        }

        reader.readAsArrayBuffer(file);
        
    }

    const getPaginationOptions = useMemo(() => {
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                 ? dispatch(pastPage())
                 : dispatch(getComissionsReport({agencyId: currentUser.agency_id, billingPeriodId: +selectedBillingPeriod, page: value, pageSize: paginatedOptions.pageSize, status: selectedStatus}));
            },
            setPageSize: (value: number) => dispatch(getComissionsReport({agencyId: currentUser.agency_id, billingPeriodId: +selectedBillingPeriod, page: paginatedOptions.page, pageSize: value})),
        }
    }, [dispatch, paginatedOptions]);

    return (<>
        {isLoading && <LoadingMask />}
        {showFilePreviewModal()}
        <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
        <FilteredTable
            columns={selectedStatus == 'unmatched' ? unmatchedColumns : columns}
            data={pageCommissions}
            isPaginated
            paginationOptions={getPaginationOptions}
            secondRow={<div className="flex flex-row w-full justify-end">
                <FormSelect
                    className="md:w-1/4"
                    label={t("BILLING_PERIOD")}
                    name=""
                    options={ billingPeriods.map((x:any) => ({value: x.id, label: moment({month: x.month, year: x.year}).format("MMMM, yyyy")}))}
                    selectedValue={selectedBillingPeriod}
                    handleOnChange={(e) => {
                        setSelectedBillingPeriod(e.target.value)
                        dispatch(getComissionsReport({agencyId: currentUser.agency_id, billingPeriodId: +e.target.value, pageSize: paginatedOptions.pageSize, page: 0, status: selectedStatus}))
                    }}
                />

                <FormSelect
                    className="md:w-1/4"
                    label={t("STATUS")}
                    name=""
                    options={ [{value: "confirmed", label: t("CONFIRMED")},  {value: "unmatched", label: t("UNMATCHED")}]}
                    selectedValue={selectedStatus}
                    handleOnChange={(e) => {
                        setSelectedStatus(e.target.value)
                        if(selectedBillingPeriod !== '') {
                            dispatch(getComissionsReport({agencyId: currentUser.agency_id, billingPeriodId: +selectedBillingPeriod, pageSize: paginatedOptions.pageSize, page: 0, status: e.target.value}))
                        }
                    }}
                />
            </div>}
            buttonAction={<Button onClick={() => showUploadDialog()}>
                <input 
                    type='file' 
                    id='file' 
                    ref={inputFile} 
                    accept=".csv, .xls, .xlsx, .numbers, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => handleFileSelected(e)}
                    multiple={false}
                    style={{display: 'none'}}/>
                <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><DocumentArrowUpIcon /></div>
                    {t('UPLOAD_COMMISSIONS')}
                </span>
            </Button>}
            secondaryActionButton={
                <Button isTerciary={true} classNames="ml-3" onClick={exportReport} isDisabled={pageCommissions.length === 0}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><DocumentArrowDownIcon /></div>
                        {t('DOWNLOAD_REPORT')}
                    </span>
                </Button>
            }
        />
    </>
    )
}