
import { Controller, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "./FormInput";

interface Props {
    name: string
    value?: string | number
    label: string
    type?: string
    className?: string
    placeholder?: string
    isDisabled?: boolean
    control: any
    error?: FieldError
    isRequired?: boolean
}
// Regular expression to match a valid US phone number format
const usPhoneNumberRegex = /^(\+?1\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const ReactiveFormPhoneInput = (props: Props) => {
    const { t } = useTranslation();

    const formatPhoneNumber = (input: any) => {
        if (!input) return ''; // Handle empty input
        if (input.substring)
        input = input.replace(/\D/g, ''); // Remove non-numeric characters
        input = input.substring(1);
        if (input.length <= 3) {
            return `(${input}`;
        } else if (input.length <= 6) {
            return `(${input.slice(0, 3)}) ${input.slice(3)}`;
        } else {
            return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
        }
    };

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{
                required: props.isRequired ?? false,
                pattern: {
                    value: usPhoneNumberRegex,
                    message: 'Invalid US phone number format',
                },
            }}
            

            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                return (<FormInput
                    label={props.label}
                    name={props.name}
                    value={formatPhoneNumber(value)}
                    type={props.type}
                    handleOnChange={(e) => {
                        let numericValue = e.target.value
                        numericValue = numericValue.replace(/\D/g, ''); // Remove non-numeric characters
                        
                        if (numericValue.length <= 10) {
                            onChange("+1" + numericValue); // Update the form field with numeric value
                        }
                    }}
                    error={error ? error.message ? error.message : t("FIELD_REQUIRED") : ""}
                    className={props.className}
                    isDisabled={props.isDisabled}
                    placeholder={props.placeholder} />)
            }} />
    )
}