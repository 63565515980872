import i18next from "i18next";

export enum ClientFormSectionEnum {
    PERSONAL_DETAILS = 1,
    ADDRESS,
    IMMIGRATION_STATUS,
    INCOME_SOURCES,
    POLICIES,
    DEPENDENTS,
    CONSENT_DOCUMENTS,
    DOCUMENTS,
    NOTES
}

export const ClientFormSectionLabel = (key: string) => ({ 
    [ClientFormSectionEnum.PERSONAL_DETAILS]: i18next.t('PERSONAL_DETAILS'),
    [ClientFormSectionEnum.ADDRESS]: i18next.t('ADDRESS'),
    [ClientFormSectionEnum.IMMIGRATION_STATUS]: i18next.t('IMMIGRATION_STATUS'),
    [ClientFormSectionEnum.INCOME_SOURCES]: i18next.t('INCOME_SOURCES'),
    [ClientFormSectionEnum.POLICIES]: i18next.t('POLICIES'),
    [ClientFormSectionEnum.DEPENDENTS]: i18next.t('DEPENDENTS'),
    [ClientFormSectionEnum.CONSENT_DOCUMENTS]: i18next.t('CONSENT_DOCUMENTS'),
    [ClientFormSectionEnum.DOCUMENTS]: i18next.t('DOCUMENTS'),
    [ClientFormSectionEnum.NOTES]: i18next.t('NOTES')
})[key];

export const getClientFormSections = () =>
    Object.values(ClientFormSectionEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: ClientFormSectionLabel(value) ?? ""
        }
    });