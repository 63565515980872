import { createAsyncThunk } from "@reduxjs/toolkit";
import { Agency, BillingPeriod } from "../../models/Agency";
import { axiosClient } from "../axiosClient";
import { AxiosResponse } from "axios";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";

export const updateAgency = createAsyncThunk("agencies/update", async (agency: Agency, { rejectWithValue }) => {
    try {
        await (await axiosClient()).patch(`/agencies/${agency.id}`, {...agency});
        return {
            ...agency,
            id: agency.id
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const setCurrentAgency = createAsyncThunk("agencies/setCurrentAgency", async (agency: Agency) => {
    return {
        ...agency
    };
});

export const fetchAgencyTwilioConfigs = createAsyncThunk('getAgencyTwilioConfigs', async () => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/agencies/twilio-config`);
    return response.data
});

export const createAgencyTwilioConfig = createAsyncThunk("agencies/createTwilioConfig", async (data: any, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/agencies/twilio-config`, { ...data });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchAgencyTwilioConfig = createAsyncThunk('getAgencyTwilioConfig', async (twilioConfigId: number) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/agencies/twilio-config/${twilioConfigId}`);
    return response.data
});

export const updateAgencyTwilioConfig = createAsyncThunk("agencies/updateTwilioConfig", async (params: {twilioConfigId: number, data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).patch(`/agencies/twilio-config/${params.twilioConfigId}`, { ...params.data });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchAgencyBillingPeriods = createAsyncThunk('agencies/fetchBillingPeriods', async (agencyId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<BillingPeriod[]> = await (await axiosClient()).get(`/agencies/${agencyId}/billing-periods`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
    
});

export const createAgencyBillingPeriod = createAsyncThunk('agencies/createBillingPeriod', async (payload: {agencyId: number, data: BillingPeriod}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<BillingPeriod> = await (await axiosClient()).post(`/agencies/${payload.agencyId}/billing-periods`, payload.data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data)
    }
    
});


export const endAgencyBillingPeriod = createAsyncThunk('agencies/endBillingPeriod', async (agencyId: number, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<BillingPeriod[]> = await (await axiosClient()).delete(`/agencies/${agencyId}/billing-periods`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data)
    }
    
});

export const fetchAgencyUserCommissionRules = createAsyncThunk('agencies/fetchAgencyUserCommissionRules', async (params: {agencyId: number, userId: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<AgentCommissionRule[]> = await (await axiosClient()).get(`/agencies/${params.agencyId}/users/${params.userId}/commission-rules`);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});

export const createAgencyUserCommissionRule = createAsyncThunk('agencies/createAgencyUserCommissionRule', async (params: {agencyId: number, userId: number, payload: {user_id: number, data: AgentCommissionRule}[]}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<AgentCommissionRule[]> = await (await axiosClient()).post(`/agencies/${params.agencyId}/users/${params.userId}/commission-rules`, params.payload);
        return response.data
    } catch(error: any) {
        return rejectWithValue(error.response.data)
    }
});